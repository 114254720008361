import axios from "axios";
import MDButton from "components/MDButton";
import {useNavigate} from "react-router-dom";

function DeleteButton({nickname}) {
  const navigate = useNavigate();
  const deleteClient = async () => {
    const token = localStorage.getItem("_token");
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/settings/account`, {
            data: {
              nickname: nickname,
            },
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
      );

    } catch (error) {
      if (error.code === "ERR_NETWORK") {
        console.error("No connection with server")
      } else if (error.response.status === 401) {
        navigate("/sign-in");
      }
    }
    window.location.reload()
  };

  return (
      <MDButton
          component="button"
          onClick={deleteClient}
          variant="text"
          color="error"
          fontWeight="medium"
      >
        Delete
      </MDButton>
  );
}

export default DeleteButton;
