import { useState } from "react";
import Switch from "@mui/material/Switch";
import MDBox from "components/MDBox";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";

function OnOffTrigger({ status, adsId, userId, price, side, isVisibility, ticker }) {
    const [adsStatus, setAdsStatus] = useState(status);

    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const changeAdsVisibility = async (newStatus) => {
        const token = localStorage.getItem("_token");
        try {
            await axios.put(`${process.env.REACT_APP_API_URL}/dashboard/ads`, {
                user_id: userId,
                ads_id: adsId,
                visibility: newStatus,
                side: side,
                price: price,
                is_visibility: isVisibility,
                ticker: ticker
            },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );
        } catch (error) {
            if (error.code === "ERR_NETWORK") {
                enqueueSnackbar("No connection with server", {
                    variant: "error",
                    persist: true,
                    anchorOrigin: { horizontal: "center", vertical: "top" },
                });
            } else if (error.response.status === 401) {
                navigate("/sign-in");
            }
        }
    };

    const handleAdsVisibilityMode = () => {
        const newStatus = !adsStatus;
        setAdsStatus(newStatus);
        changeAdsVisibility(newStatus);
    }

    return (
        <MDBox pt={1} pb={1}>
            <Switch checked={adsStatus} onChange={handleAdsVisibilityMode} />
        </MDBox>
    );
}

export default OnOffTrigger;
