import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import axios from "axios";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

function PriceInput({ userId, adsId, traderNickname, side, currency, currentStepPrice, isOnline }) {
    const [stepPrice, setStepPrice] = useState("");
    const [debounceTimeout, setDebounceTimeout] = useState(null);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    console.log(`Current price: ${currentStepPrice}`);

    useEffect(() => {
        if (currentStepPrice) {
            setStepPrice(currentStepPrice);
        }
    }, []);

    const sendPriceChanges = async (price) => {
        const token = localStorage.getItem("_token");
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/dashboard/ads`, {
                user_id: userId,
                trader_nickname: traderNickname,
                ads_id: adsId,
                side: side,
                step_price: price,
                currency: currency
            },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );
            enqueueSnackbar("Price updated successfully", {
                variant: "success",
                anchorOrigin: { horizontal: "center", vertical: "top" },
            });
        } catch (error) {
            if (error.code === "ERR_NETWORK") {
                enqueueSnackbar("No connection with server", {
                    variant: "error",
                    persist: true,
                    anchorOrigin: { horizontal: "center", vertical: "top" },
                });
            } else if (error.response?.status === 401) {
                navigate("/sign-in");
            } else if (error.response?.status === 400) {
                enqueueSnackbar("Invalid data", {
                    variant: "error",
                    anchorOrigin: { horizontal: "center", vertical: "top" },
                });
            }
        }
    };

    const handleChangePrice = (e) => {
        const newStepPrice = e.target.value;
        setStepPrice(newStepPrice);

        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }

        const timeout = setTimeout(() => {
            if (newStepPrice !== "") {
                if (isOnline) {
                    sendPriceChanges(newStepPrice);
                    localStorage.setItem(`${userId}_${adsId}`, newStepPrice);
                    enqueueSnackbar(`The price step for ${traderNickname}, currency: ${currency} has been successfully changed. New value ${newStepPrice}`, {
                        variant: "success",
                        anchorOrigin: { horizontal: "right", vertical: "bottom" },
                    });
                }
            }
        }, 3000);

        setDebounceTimeout(timeout);
    };

    return (
        <MDBox>
            <MDInput
                name="change_price"
                type="number"
                label="%"
                step="0.1"
                min="0"
                value={stepPrice}
                onChange={handleChangePrice}
            />
        </MDBox>
    );
}

export default PriceInput;
