import {useEffect, useState} from "react";
import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import {ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";
import theme from "assets/theme";
import themeDark from "assets/theme-dark";
import routes from "routes";
import {setMiniSidenav, useMaterialUIController} from "context";
import brandWhite from "assets/images/logo.png";
import brandDark from "assets/images/logo.png";
import {useSnackbar} from "notistack";

export default function App() {
    const [controller, dispatch] = useMaterialUIController();
    const {
        miniSidenav,
        direction,
        layout,
        sidenavColor,
        transparentSidenav,
        whiteSidenav,
        darkMode,
    } = controller;

    const [onMouseEnter, setOnMouseEnter] = useState(false);
    const {pathname} = useLocation();
    let client_id = localStorage.getItem("client_id");
    const {enqueueSnackbar} = useSnackbar();

    const [showSidenav, setShowSidenav] = useState(true);

    const handleOnMouseEnter = () => {
        if (miniSidenav && !onMouseEnter) {
            setMiniSidenav(dispatch, false);
            setOnMouseEnter(true);
        }
    };

    const handleOnMouseLeave = () => {
        if (onMouseEnter) {
            setMiniSidenav(dispatch, true);
            setOnMouseEnter(false);
        }
    };

    useEffect(() => {
        document.body.setAttribute("dir", direction);
    }, [direction]);

    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
    }, [pathname]);

    function generateRandomHex(length) {
        const characters = "abcdef0123456789";
        let result = "";
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
    }

    useEffect(() => {
        if (pathname !== "/sign-in") {
            if (!client_id) {
                client_id = generateRandomHex(8);
                localStorage.setItem("client_id", client_id);
            }

            const ws = new WebSocket(`${process.env.REACT_APP_WS_URL}/${client_id}`);

            ws.onmessage = (event) => {
                const data = JSON.parse(event.data);
                if (data.event === "ping") {
                    ws.send("pong");
                } else if (data.event === "log") {
                    handleLog(data.data);
                }
            };

            ws.onclose = (event) => {
                if (event.code !== 1006) {
                    enqueueSnackbar("The server closed the connection", {
                        variant: "error",
                        persist: true,
                        anchorOrigin: {horizontal: "center", vertical: "top"},
                    });
                }
            };

            const handleLog = (data) => {
                const message = data.message;
                enqueueSnackbar(message, {
                    variant: data.type,
                    anchorOrigin: {horizontal: "right", vertical: "bottom"},
                });
            };
        }

        return () => {
            // ws.close();
        };

    }, [enqueueSnackbar, pathname]);

    const getRoutes = (allRoutes) =>
        allRoutes.map((route) => {
            if (route.collapse) {
                return getRoutes(route.collapse);
            }

            if (route.route) {
                return <Route exact path={route.route} element={route.component} key={route.key}/>;
            }
            return null;
        });

    return (
        <ThemeProvider theme={darkMode ? themeDark : theme}>
            <CssBaseline/>
            {layout === "dashboard" && showSidenav && (
                <>
                    <Sidenav
                        color={sidenavColor}
                        brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
                        brandName="P2P Bot"
                        routes={routes}
                        onMouseEnter={handleOnMouseEnter}
                        onMouseLeave={handleOnMouseLeave}
                    />
                </>
            )}
            {layout === "vr" && <Configurator/>}
            <Routes>
                {getRoutes(routes)}
                <Route path="*" element={<Navigate to="/sign-in"/>}/>
            </Routes>
        </ThemeProvider>
    );
}